import { Injectable, Inject } from '@angular/core';
import { Headers, Http } from '@angular/http';
import 'rxjs/add/operator/toPromise';

import { Employee } from './employee.model';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EmployeeService {
    private employeesUrl = '/employees';
    private headers = new Headers({'Content-Type': 'application/json'});

    constructor(private httpClient: HttpClient, private http: Http, @Inject('BASE_URL') private baseUrl: string) {}

    private handleError(error: any): Promise<any> {
        //console.error('Fehler!!', error);
        return Promise.reject("FEHLER: " + (error.json().code || error));
    }

    getEmployee(id: number): Promise<Employee>{
        const url = `${this.employeesUrl}/${id}`;
        /*return this.http.get(url)
            .toPromise()
            .then(response => response.json().data as Customer)
            .catch(this.handleError);*/

            return this.http.get(url)
            .toPromise()
            .then(response => response.json() as Employee)
            .catch(this.handleError);
    }

    getEmployees(): Promise<Employee[]>{
        return this.http.get(this.employeesUrl)
                .toPromise()
                .then(response => response.json() as Employee[])
                .catch(this.handleError);

    }

    getEmployeesViaHttpClient() {
        return this.httpClient.get<Employee[]>(this.baseUrl + this.employeesUrl);
    }

    update(employee: Employee): Promise<Employee>{
        const url = `${this.employeesUrl}/${employee.id}`;
        return this.http
            .put(url, JSON.stringify(employee), {headers: this.headers})
            .toPromise()
            .then(() => employee)
            .catch(this.handleError);
    }

    create(name: string): Promise<Employee> {
        return this.http
            .post(this.employeesUrl, JSON.stringify({name: name}), {headers: this.headers})
            .toPromise()
            .then(() => null)
            .catch(this.handleError);
    }

    delete(id: number): Promise<void> {
        const url = `${this.employeesUrl}/${id}`;
        return this.http.delete(url, {headers: this.headers})
            .toPromise()
            .then(() => null)
            .catch(this.handleError);
    }

}