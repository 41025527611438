import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class RootAuthGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (sessionStorage.getItem('currentUser')) {
            if (JSON.parse(sessionStorage.getItem('currentUser')).username === "bestadmin")
            {
                // logged in so return true
                return true;
            }
            else
            {
                this.router.navigate(['/login'], { queryParams: { returnUrl: '/home' } });
                return false;
            }
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        //this.router.navigate(['/login'], { });
        return false;
    }
}