import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Job } from '../shared/job.model';
import { JobService } from '../shared/job.service';

@Component({
  selector: 'job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.css'],

})

export class JobListComponent implements OnInit {
  jobs: Job[];
  selectedJob: Job;
  errorMessage: string;
  isWoman: boolean = true;

  constructor(
    private router: Router,
    private jobService: JobService) { }

  getJobs(): void {
    this.jobService.getJobs().then(jobs => this.jobs = jobs);
  }

  ngOnInit(): void {
    this.getJobs();
  }

  onSelect(job: Job): void {
    this.selectedJob = job;
    this.gotoDetail();
  }

  gotoDetail(): void {
    this.router.navigate(['/jobs', this.selectedJob.id]);
  }

  add(isWoman: boolean, description: string, price: number): void{
    description = description.trim();
    if(!description || !price){return;}
    var newJob = {isWoman: isWoman, description: description, price: price};
    this.jobService.create(newJob as Job)
      .then(
        /*(job: Job) => {
        this.jobs.push(job);
        this.selectedJob = null;
        }*/
      job => {this.getJobs()}
    );
  }

  delete(job: Job): void {
    /*
    this.customerService
      .delete(customer.id)
      .then(() => {
        this.customers = this.customers.filter(c => c !== customer);
        if (this.selectedCustomer === customer) { this.selectedCustomer = null; }
      });
      */
  }
}