import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from './shared/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'BESTCUTS | Statistik';

  constructor(private authenticationService: AuthenticationService){

  }

  logout(){
    this.authenticationService.logout();
  }
}
