import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';

@Injectable()
export class CashPointConfigService {
    private cashPointUrl = '/cashPoint';

    constructor(private httpClient: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

    isOpen() {
        const url = `${this.cashPointUrl}/isOpen`;
        return this.httpClient.get<boolean>(this.baseUrl + url);
    }

    open() {
        const url = `${this.cashPointUrl}/open`;
        return this.httpClient.get(this.baseUrl + url);
    }

    close() {
        const url = `${this.cashPointUrl}/close`;
        return this.httpClient.get(this.baseUrl + url);
    }

    /*create(payment: Payment): Promise<Payment> {
        payment.date = new Date(Date.now());
        return this.http
            .post(this.paymentsUrl, JSON.stringify(payment), { headers: this.headers })
            .toPromise()
            .then(() => null)
            .catch(this.handleError);
    }*/

}

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};