import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Employee } from '../shared/employee.model';
import { EmployeeService } from '../shared/employee.service';

@Component({
  selector: 'employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css'],

})

export class EmployeeListComponent implements OnInit {
  employees: Employee[];
  selectedEmployee: Employee;
  errorMessage: string;

  constructor(
    private router: Router,
    private employeeService: EmployeeService) { }

  getEmployees(): void {
    this.employeeService.getEmployees().then(employees => this.employees = employees);
  }

  ngOnInit(): void {
    this.getEmployees();
  }

  onSelect(employee: Employee): void {
    this.selectedEmployee = employee;
    this.gotoDetail();
  }

  gotoDetail(): void {
    this.router.navigate(['/employees', this.selectedEmployee.id]);
  }
  
  add(name: string): void{
    name = name.trim();
    if(!name){return;}
    this.employeeService.create(name)
      .then(
      job => {this.getEmployees()}
    );
  }

  delete(employee: Employee): void {
    /*
    this.customerService
      .delete(customer.id)
      .then(() => {
        this.customers = this.customers.filter(c => c !== customer);
        if (this.selectedCustomer === customer) { this.selectedCustomer = null; }
      });
      */
  }
}