import { Injectable, Inject } from '@angular/core';
import { Headers, Http } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import 'rxjs/add/operator/toPromise';

import { Payment } from './payment.model';

@Injectable()
export class PaymentService {
    private paymentsUrl = '/payments';
    private headers = new Headers({ 'Content-Type': 'application/json' });

    constructor(private httpClient: HttpClient, private http: Http, @Inject('BASE_URL') private baseUrl: string) { }

    private handleError(error: any): Promise<any> {
        //console.error('Fehler!!', error);
        return Promise.reject("FEHLER: " + (JSON.parse(error).code || error));
    }

    getPayment(id: number): Promise<Payment> {
        const url = `${this.paymentsUrl}/${id}`;
        /*return this.http.get(url)
            .toPromise()
            .then(response => response.json().data as Customer)
            .catch(this.handleError);*/

        return this.http.get(url)
            .toPromise()
            .then(response => response.json() as Payment)
            .catch(this.handleError);
    }

    getSumOfToday(): Promise<any> {
        const url = `/statistics/today`;
        return this.http.get(url)
            .toPromise()
            .then(response => response.json())
            .catch(this.handleError);
    }

    getPayments(): Promise<Payment[]> {
        return this.http.get(this.paymentsUrl)
            .toPromise()
            .then(response => response.json() as Payment[])
            .catch(this.handleError);

    }

    /*getPaymentsByDate(startDate: Date, endDate: Date, employeeId: number): Promise<Payment[]> {
        var endDateMod = new Date(endDate.getTime());
        endDateMod.setDate(endDateMod.getDate() + 1);
        const url = employeeId ? `${this.paymentsUrl}/${this.formatMMDDYYYY(startDate)}/${this.formatMMDDYYYY(endDateMod)}/${employeeId}` : `${this.paymentsUrl}/${this.formatMMDDYYYY(startDate)}/${this.formatMMDDYYYY(endDateMod)}/`;
        //const url = `${this.paymentsUrl}/date=${this.formatMMDDYYYY(startDate)}`;
        return this.http.get(url)
            .toPromise()
            .then(response => response.json() as Payment[])
            .catch(this.handleError);

    }*/

    getPaymentsByDate(startDate: Date, endDate: Date, employeeId: number) {
        var endDateMod = new Date(endDate.getTime());
        endDateMod.setDate(endDateMod.getDate() + 1);
        const url = employeeId ? `${this.paymentsUrl}/${this.formatMMDDYYYY(startDate)}/${this.formatMMDDYYYY(endDateMod)}/${employeeId}` : `${this.paymentsUrl}/${this.formatMMDDYYYY(startDate)}/${this.formatMMDDYYYY(endDateMod)}/`;
        return this.httpClient.get<Payment[]>(this.baseUrl + url);
    }

    /*update(payment: Payment): Promise<Payment>{
        const url = `${this.paymentsUrl}/${payment.id}`;
        return this.http
            .put(url, JSON.stringify(payment), {headers: this.headers})
            .toPromise()
            .then(() => payment)
            .catch(this.handleError);
    }*/

    create(payment: Payment): Promise<Payment> {
        payment.date = new Date(Date.now());
        return this.http
            .post(this.paymentsUrl, JSON.stringify(payment), { headers: this.headers })
            .toPromise()
            .then(() => null)
            .catch(this.handleError);
    }

    /*create(payment: Payment): Promise<Payment> {
        return this.http
            .post(this.paymentsUrl, JSON.stringify({
                                        description: payment.description,
                                        price: payment.price,
                                        isEC: payment.isEC,
                                        cashierId: payment.cashierId,
                                        date: Date.now()
                                    }), {headers: this.headers})
            .toPromise()
            .then(() => null)
            .catch(this.handleError);
    }*/

    /*create(description: string, price: number, isEC: number, cashierId: number): Promise<Payment> {
        return this.http
            .post(this.paymentsUrl, JSON.stringify({
                                        description: description,
                                        price: price,
                                        isEC: isEC,
                                        cashierId: cashierId,
                                        date: Date.now()
                                    }), {headers: this.headers})
            .toPromise()
            .then(() => null)
            .catch(this.handleError);
    }*/

    delete(id: number): Promise<void> {
        const url = `${this.paymentsUrl}/${id}`;
        return this.http.delete(url, { headers: this.headers })
            .toPromise()
            .then(() => null)
            .catch(this.handleError);
    }

    private formatMMDDYYYY(date: Date): string {
        return (date.getFullYear()) + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    }

}

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};