import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';

import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';

import { Employee } from '../shared/employee.model';
import { EmployeeSearchService } from '../shared/employee-search.service';

@Component({
  selector: 'employee-search',
  templateUrl: './employee-search.component.html',
  styleUrls: ['./employee-search.component.css'],
  providers: [EmployeeSearchService]
})

export class EmployeeSearchComponent implements OnInit {
  employees: Observable<Employee[]>;
  private searchTerms = new Subject<string>();

  constructor(
    private employeeSearchService: EmployeeSearchService,
    private router: Router) {}
  
  search(term: string): void {
    this.searchTerms.next(term);
  }

  ngOnInit(): void {
        this.employees = this.searchTerms
            .debounceTime(300)
            .distinctUntilChanged()
            .switchMap(term => term ? this.employeeSearchService.search(term)
                                    : Observable.of<Employee[]>([]))
            .catch(error => { console.log(error); return Observable.of<Employee[]>([]);});
    }

  gotoDetail(employee: Employee): void {
    let link = ['/employees', employee.id];
    this.router.navigate(link);
  }
}