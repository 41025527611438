import { NgModule, LOCALE_ID } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http'
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import { JwtInterceptorProvider } from './shared/jwt.interceptor';

import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';



import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatStepperModule,
} from '@angular/material';

import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AdminComponent } from './admin/admin.component';
import { EmployeeComponent } from './employees/employee/employee.component';
import { EmployeeListComponent } from './employees/employee-list/employee-list.component';
import { EmployeeService } from './employees/shared/employee.service';
import { EmployeeSearchComponent } from './employees/employee-search/employee-search.component';
import { PaymentComponent } from './payments/payment/payment.component';
import { PaymentListSimpleComponent } from './payments/payment-list-simple/payment-list-simple.component';
import { PaymentListComponent } from './payments/payment-list/payment-list.component';
import { PaymentService } from './payments/shared/payment.service';
import { JobComponent } from './jobs/job/job.component';
import { JobListComponent } from './jobs/job-list/job-list.component';
import { JobService } from './jobs/shared/job.service';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from './guards/auth.guard';
import { RootAuthGuard } from './guards/root.auth.guard';
import { AuthenticationService } from './shared/auth.service';
import { LoginComponent } from './login/login.component';
import { CashPointConfigService } from './admin/services/cash-point-config.service'

import { YesNoPipe } from './pipes/yes-no.pipe';
import { WomanManPipe } from './pipes/woman-man.pipe';
import { TotalPricePipe } from './pipes/total-price.pipe';

import { CustomHttpProvider } from './shared/custom-http';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    CommonModule,
    HttpClientModule,
    HttpModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatStepperModule,
    InputTextModule,
    CheckboxModule,
    RadioButtonModule,
    ButtonModule,
    CardModule
  ],
  declarations: [
    AppComponent,
    AdminComponent,
    EmployeeComponent,
    EmployeeListComponent,
    EmployeeSearchComponent,
    PaymentComponent,
    PaymentListSimpleComponent,
    PaymentListComponent,
    JobComponent,
    JobListComponent,
    RegisterComponent,
    LoginComponent,
    YesNoPipe,
    WomanManPipe,
    TotalPricePipe
  ],
  providers: [CustomHttpProvider, AuthGuard, RootAuthGuard, AuthenticationService, EmployeeService, PaymentService, JobService, CashPointConfigService, JwtInterceptorProvider, { provide: LOCALE_ID, useValue: 'de-DE' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
