import 'rxjs/add/operator/switchMap';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { DataSource } from '@angular/cdk/collections';

import { Payment } from '../payments/shared/payment.model';
import { PaymentService } from '../payments/shared/payment.service';
import { Employee } from '../employees/shared/employee.model';
import { EmployeeService } from '../employees/shared/employee.service';
import { Job } from '../jobs/shared/job.model';
import { JobService } from '../jobs/shared/job.service';
import { CashPointConfigService } from '../admin/services/cash-point-config.service';


@Component({
  selector: 'register-detail',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})

export class RegisterComponent implements OnInit {
  addOn: number;
  payment: Payment;
  employees: Employee[];
  selectedEmployee: Employee;
  isEC: boolean;
  womanJobs: Job[];
  manJobs: Job[];
  activeJobs: Job[];
  errorMessage: string;
  greenMessage: string;
  lastTime: number;
  isOpen: boolean;

  constructor(
    private paymentService: PaymentService,
    private employeeService: EmployeeService,
    private jobService: JobService,
    private cashPointService: CashPointConfigService,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.addOn;
    this.activeJobs = new Array<Job>();
  }

  getEmployeesAndJobs(): void {
    this.cashPointService.isOpen().subscribe(
      data => {
        this.isOpen = data;
      },
      error => {
        this.isOpen = false;
      }
    );
    this.employeeService.getEmployees().then(employees => this.employees = employees);
    this.jobService.getJobsByGroup(0).then(manJobs => this.manJobs = this.sortJobsByCategory(manJobs));
    this.jobService.getJobsByGroup(1).then(womanJobs => this.womanJobs = this.sortJobsByCategory(womanJobs));
  }

  private sortJobsByCategory(jobsToSort: Job[]) {
    const sortedByCategory: Job[] = [];
    jobsToSort.forEach(job => {
      if (!!job.jobCategory) {
        const jobCategory = sortedByCategory.find(j => j.jobCategory === job.jobCategory);
        if (jobCategory) {
          jobCategory.concreteJobs.push(job);
          if(job.price < jobCategory.minPrice) {
            jobCategory.minPrice = job.price;
          }
          if(job.price > jobCategory.maxPrice) {
            jobCategory.maxPrice = job.price;
          }
        } else {
          const jobCategory: Job = {
            jobCategory: job.jobCategory,
            imageUrl: job.imageUrl,
            concreteJobs: [job],
            minPrice: job.price,
            maxPrice: job.price
          };
          sortedByCategory.push(jobCategory);
        }
      } else {
        sortedByCategory.push(job);
      }
    });
    return sortedByCategory;
  }

  ngOnInit(): void {
    this.getEmployeesAndJobs();
  }

  onSelect(employee: Employee): void {
    this.selectedEmployee = employee;
  }

  onAdd(selectedJob: Job): void {
    //this.totalPrice += selectedJob.price;
    if (this.activeJobs.every(function (job) {
      if (job.id === selectedJob.id) {
        job.count++;
        return false;
      }
      return true;
    })) {
      selectedJob.count = 1;
      this.activeJobs.push(selectedJob);
    }
  }

  onRemove(selectedJob: Job): void {
    var index = this.activeJobs.indexOf(selectedJob);
    if (index > -1) {
      this.activeJobs[index].count--;
      //this.totalPrice -= selectedJob.price;
      if (this.activeJobs[index].count == 0) {
        this.activeJobs.splice(index, 1);
      }
    }
  }

  goBack(): void {
    this.location.back();
  }

  enter(): void {
    if (this.selectedEmployee && this.activeJobs.length > 0) {
      this.addOn = this.addOn ? this.addOn : 0;
      var payment = { jobs: this.activeJobs, addOn: this.addOn, isEC: this.isEC, cashierId: this.selectedEmployee.id } as Payment;

      this.paymentService.create(payment).
        then(paymentParam => { this.clear(); this.greenMessage = "Zahlung war erfolgreich."; })
        .catch(error => { this.clear(true); this.errorMessage = error; });
    }
    else {
      this.clear(true); this.errorMessage = "Zum Eintragen bitte zuerst einen Kassierer und mindestens eine Dienstleistung auswählen."
    }
  }

  clear(err: boolean = false): void {
    if (!err) {
      delete this.payment;
      delete this.selectedEmployee;
      delete this.isEC;
      this.activeJobs = new Array<Job>();
      delete this.addOn;
    }
    delete this.errorMessage;
    delete this.greenMessage;
    this.lastTime = Date.now();
  }

}