import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Payment } from '../shared/payment.model';
import { PaymentService } from '../shared/payment.service';
import { Employee } from '../../employees/shared/employee.model';
import { EmployeeService } from '../../employees/shared/employee.service';

@Component({
  selector: 'payment-list',
  templateUrl: './payment-list-simple.component.html',
  styleUrls: ['../payment-list/payment-list.component.css'],

})

export class PaymentListSimpleComponent implements OnInit {
  payments: Payment[];
  selectedPayment: Payment;
  employees: Employee[];
  errorMessage: string;
  isRequesting: boolean;

  constructor(
    private router: Router,
    private paymentService: PaymentService,
    private employeeService: EmployeeService) {
    }


    initialize(): void {
      this.payments = [];
      this.getPayments();
    }

  /*getPayments(): void {
    var today = new Date();
    this.paymentService.getPaymentsByDate(today, today, null).then(
      function (payments) {
        this.payments = payments;
        this.employeeService.getEmployees().then(function (employees) {
          this.employees = employees;
          this.payments.forEach(function (payment) {
            payment.totalPrice = payment.addOn;
            payment.cashierName = employees.find(value => { return value.id == payment.cashierId }).name;
            payment.jobs.forEach(function (job) {
              payment.totalPrice += job.price * job.count;
            });
          }.bind(this));
        }.bind(this));
      }.bind(this));
  }*/

  /*getPayments(): void {
    var today = new Date();
    this.paymentService.getPaymentsByDate(today, today, null).subscribe(
      result => function (payments) {
        this.payments = payments;
        this.employeeService.getEmployees().then(function (employees) {
          this.employees = employees;
          this.payments.forEach(function (payment) {
            payment.totalPrice = payment.addOn;
            payment.cashierName = employees.find(value => { return value.id == payment.cashierId }).name;
            payment.jobs.forEach(function (job) {
              payment.totalPrice += job.price * job.count;
            });
          }.bind(this));
        }.bind(this));
      }.bind(this),
      error => {});
  }*/

  getPayments(): void {
    this.isRequesting = true;
    var today = new Date();
    this.paymentService.getPaymentsByDate(today, today, null).subscribe(
      result => {
        this.payments = result;
        this.employeeService.getEmployees().then(employees => {
          this.employees = employees;
          this.payments.forEach(function (payment) {
            payment.totalPrice = payment.addOn;
            payment.cashierName = employees.find(value => { return value.id == payment.cashierId }).name;
            payment.jobs.forEach(function (job) {
              payment.totalPrice += job.price * job.count;
            });
          }.bind(this),
          error => {this.isRequesting = false});
        });
        this.isRequesting = false;
      },
      error => { this.isRequesting = false; });
  }


  ngOnInit(): void {
    this.initialize();
  }

  onSelect(payment: Payment): void {
    this.selectedPayment = payment;
    this.gotoDetail();
  }

  gotoDetail(): void {
    this.router.navigate(['/payments', this.selectedPayment.id]);
  }
}