import 'rxjs/add/operator/switchMap';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';

import { Job } from '../shared/job.model';
import { JobService } from '../shared/job.service';

@Component({
    selector: 'job-detail',
    templateUrl: './job.component.html',
    styleUrls: ['./job.component.css'],
})

export class JobComponent implements OnInit {
  job: Job;
  errorMessage: string;

  constructor(
    private jobService: JobService,
    private route: ActivatedRoute,
    private location: Location
  ){}

  ngOnInit(): void {
    //methods declared here are called when this component is initialized    
    this.route.paramMap.
    switchMap((params: ParamMap) => this.jobService.getJob(+params.get('id')))
    .subscribe(job => this.job = job);
  }

  goBack(): void {
      // TODO: goBack guard needed
      this.location.back();
  } 

  save(): void {
    this.jobService.update(this.job).
      then(() => this.goBack())
      .catch(error => {this.errorMessage = error;});
  }

  remove(): void {
    this.jobService.delete(this.job.id).then(() => this.goBack())
    .catch(error => {this.errorMessage = error;});
  }

}