import { Injectable } from '@angular/core';
import { Headers, Http } from '@angular/http';
import 'rxjs/add/operator/toPromise';

import { Job } from './job.model';

@Injectable()
export class JobService {
    private jobsUrl = '/jobs';
    private headers = new Headers({'Content-Type': 'application/json'});

    constructor(private http: Http) {}

    private handleError(error: any): Promise<any> {
        //console.error('Fehler!!', error);
        return Promise.reject("FEHLER: " + (error.json().code || error));
    }

    getJob(id: number): Promise<Job>{
        const url = `${this.jobsUrl}/${id}`;
        /*return this.http.get(url)
            .toPromise()
            .then(response => response.json().data as Customer)
            .catch(this.handleError);*/

            return this.http.get(url)
            .toPromise()
            .then(response => response.json() as Job)
            .catch(this.handleError);
    }

    getJobsByGroup(group: number): Promise<Job[]>{
        const url = `${this.jobsUrl}/group=${group}`;
        return this.http.get(url)
                .toPromise()
                .then(response => response.json() as Job[])
                .catch(this.handleError);

    }

    getJobs(): Promise<Job[]>{
        return this.http.get(this.jobsUrl)
                .toPromise()
                .then(response => response.json() as Job[])
                .catch(this.handleError);

    }

    update(job: Job): Promise<Job>{
        const url = `${this.jobsUrl}/${job.id}`;
        return this.http
            .put(url, JSON.stringify(job), {headers: this.headers})
            .toPromise()
            .then(() => job)
            .catch(this.handleError);
    }
    
    create(job: Job): Promise<Job> {
        return this.http
            .post(this.jobsUrl, JSON.stringify(job)
                                    /*JSON.stringify({
                                        isWoman: job.isWoman,
                                        description: job.description,
                                        price: job.price
                                    })*/, {headers: this.headers})
            .toPromise()
            .then(() => null)
            .catch(this.handleError);
    }

    /*create(description: string, price: number, isEC: number, cashierId: number): Promise<Job> {
        return this.http
            .post(this.jobsUrl, JSON.stringify({
                                        description: description,
                                        price: price,
                                        isEC: isEC,
                                        cashierId: cashierId,
                                        date: Date.now()
                                    }), {headers: this.headers})
            .toPromise()
            .then(() => null)
            .catch(this.handleError);
    }*/

    delete(id: number): Promise<void> {
        const url = `${this.jobsUrl}/${id}`;
        return this.http.delete(url, {headers: this.headers})
            .toPromise()
            .then(() => null)
            .catch(this.handleError);
    }

}