import {Pipe, PipeTransform} from '@angular/core';
import {Job} from '../jobs/shared/job.model'

@Pipe({name: 'totalPrice',
pure: false})
export class TotalPricePipe implements PipeTransform {
    transform(value: { jobs: Array<Job>, addOn: number}): number {
        if(!value.addOn)
        {
            value.addOn = 0;
        }
        var total = value.addOn;
        for(var i = 0; i < value.jobs.length; i++)
        {
            total += value.jobs[i].price * value.jobs[i].count;
        }
        return total;
    }
}