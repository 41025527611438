import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'womanMan'})
/*export class WomanManPipe implements PipeTransform {
    transform(value: boolean): string {
        return value ? 'Damen' : 'Herren';
    }
}*/
export class WomanManPipe implements PipeTransform {
    transform(value: string): boolean {
        if(value == 'Damen')
        {
            return true;
        }
        return false;
    }
}