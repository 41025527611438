import 'rxjs/add/operator/switchMap';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';

import { Payment } from '../shared/payment.model';
import { PaymentService } from '../shared/payment.service';
import { Employee } from '../../employees/shared/employee.model';
import { EmployeeService } from '../../employees/shared/employee.service';
import { Job } from '../../jobs/shared/job.model';


@Component({
  selector: 'payment-detail',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
})

export class PaymentComponent implements OnInit {
  payment: Payment;
  cashierName: string;
  errorMessage: string;

  constructor(
    private paymentService: PaymentService,
    private employeeService: EmployeeService,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit(): void {  
    this.route.paramMap.
      switchMap((params: ParamMap) => this.paymentService.getPayment(+params.get('id')))
      .subscribe(payment => {
        this.payment = payment;
        this.employeeService.getEmployee(payment.cashierId).then(employee => this.cashierName = employee.name);
      });
  }

  /*onSelect(employee: Employee): void {
    this.selectedEmployee = employee;
    this.payment.cashierId = employee.id;
  }*/

  /*onAdd(selectedJob: Job): void {
    if (this.payment.jobs.every(function (job) {
      if (job.id === selectedJob.id) {
        job.count++;
        return false;
      }
      return true;
    })) {
      selectedJob.count = 1;
      this.payment.jobs.push(selectedJob);
    }
  }

  onRemove(selectedJob: Job): void {
    var index = this.payment.jobs.indexOf(selectedJob);
    if (index > -1) {
      this.payment.jobs[index].count--;
      if (this.payment.jobs[index].count == 0) {
        this.payment.jobs.splice(index, 1);
      }
    }
  }*/

  goBack(): void {
    this.location.back();
  }

  /*save(): void {
    this.paymentService.update(this.payment).
      then(() => this.goBack())
      .catch(error => { this.errorMessage = error; });
  }*/

  remove(): void {
    this.paymentService.delete(this.payment.id).then(() => this.goBack())
      .catch(error => { this.errorMessage = error; });
  }

}