import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Payment } from '../shared/payment.model';
import { PaymentService } from '../shared/payment.service';
import { Employee } from '../../employees/shared/employee.model';
import { EmployeeService } from '../../employees/shared/employee.service';

@Component({
  selector: 'payment-list',
  templateUrl: './payment-list.component.html',
  styleUrls: ['./payment-list.component.css'],

})

export class PaymentListComponent implements OnInit {
  payments: Payment[];
  selectedPayment: Payment;
  selectedEmployeeId: number;
  employees: Employee[];
  errorMessage: string;
  sumOfCurrent: number;
  startDate = new Date();
  endDate = new Date();
  today = new Date();
  isRequesting: boolean;


  constructor(
    private router: Router,
    private paymentService: PaymentService,
    private employeeService: EmployeeService) {
  }


  initialize(): void {
    this.payments = [];
    this.sumOfCurrent = 0;
    this.getPayments();
    /*this.paymentService.getSumOfToday().then(result => {
      this.sumOfToday = result["SUMME"];
    })*/
  }

  getAllPayments(): void {
    this.paymentService.getPayments().then(
      function (payments) {
        this.payments = payments;
        this.employeeService.getEmployees().then(function (employees) {
          this.employees = employees;
          this.payments.forEach(function (payment) {
            payment.totalPrice = payment.addOn;
            payment.cashierName = employees.find(value => { return value.id == payment.cashierId }).name;
            payment.jobs.forEach(function (job) {
              payment.totalPrice += job.price * job.count;
            });
            this.sumOfCurrent += payment.totalPrice; // das bind hier auch
          }.bind(this)); // und das bind hier war auch nicht da
        }.bind(this));
      }.bind(this));
  }

  getPayments(): void {
    this.isRequesting = true;
    this.paymentService.getPaymentsByDate(this.startDate, this.endDate, this.selectedEmployeeId).subscribe(
      result => {
        this.payments = result;
        this.employeeService.getEmployeesViaHttpClient().subscribe(
          employees => {
            this.employees = employees;
            this.employees.unshift({id: null, name: 'Alle Mitarbeiter'});
            this.payments.forEach(function (payment) {
              payment.totalPrice = payment.addOn;
              payment.cashierName = employees.find(value => { return value.id == payment.cashierId }).name;
              payment.jobs.forEach(function (job) {
                payment.totalPrice += job.price * job.count;
              });
              this.sumOfCurrent += payment.totalPrice;
            }.bind(this));
            this.isRequesting = false;
          },
          error => {
            this.isRequesting = false;
          });
      },
      error => { this.isRequesting = false; });
  }

  ngOnInit(): void {
    this.initialize();
  }

  onSelect(payment: Payment): void {
    this.selectedPayment = payment;
    this.gotoDetail();
  }

  gotoDetail(): void {
    this.router.navigate(['/payments', this.selectedPayment.id]);
  }

  add(description: string, price: number): void {
    /*description = description.trim();
    if(!description || !price){return;}
    this.paymentService.create(name)
      .then(customer => {
        this.payments.push(customer);
        this.selectedPayment = null;
      });*/
  }

  delete(payment: Payment): void {
    this.paymentService
      .delete(payment.id)
      .then(() => {
        this.payments = this.payments.filter(p => p !== payment);
        if (this.selectedPayment === payment) { this.selectedPayment = null; }
      });
  }
}