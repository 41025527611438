import 'rxjs/add/operator/switchMap';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';

import { Employee } from '../shared/employee.model';
import { EmployeeService } from '../shared/employee.service';

@Component({
    selector: 'employee-detail',
    templateUrl: './employee.component.html',
    styleUrls: ['./employee.component.css'],
})

export class EmployeeComponent implements OnInit {
  employee: Employee;
  errorMessage: string;

  constructor(
    private employeeService: EmployeeService,
    private route: ActivatedRoute,
    private location: Location
  ){}

  ngOnInit(): void {
    //methods declared here are called when this component is initialized    
    this.route.paramMap.
    switchMap((params: ParamMap) => this.employeeService.getEmployee(+params.get('id')))
    .subscribe(employee => this.employee = employee);
  }

  goBack(): void {
      // TODO: goBack guard needed
      this.location.back();
  } 

  save(): void {
    this.employeeService.update(this.employee).
      then(() => this.goBack())
      .catch(error => {this.errorMessage = error});
  }

  remove(): void {
    this.employeeService.delete(this.employee.id).then(() => this.goBack())
    .catch(error => {this.errorMessage = error;});
  }

}