import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';


import { CashPointConfigService } from './services/cash-point-config.service';

@Component({
    selector: 'admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

    isRequesting: boolean;
    isOpen: boolean;
    isOpenText: string;

    constructor(
        private router: Router,
        private cashPointService: CashPointConfigService) { }

    ngOnInit(): void {
        this.isRequesting = true;
        this.cashPointService.isOpen().subscribe(
            data => {
                this.isOpen = data;
                this.isOpenText = data ? "geöffnet." : "geschlossen.";
                this.isRequesting = false;
            },
            error => {
                this.isOpen = false;
                this.isOpenText = "FEHLER: " + error;
                this.isRequesting = false;
            }
        )
    }

    goToPayments(): void {
        this.router.navigate(['/payments']);
    }
    goToEmployees(): void {
        this.router.navigate(['/employees']);
    }
    goToJobs(): void {
        this.router.navigate(['/jobs']);
    }

    clickOpen(): void {
        this.isRequesting = true;
        this.cashPointService.open().subscribe(
            data => {
                this.isOpen = true;
                this.isOpenText = "geöffnet.";
                this.isRequesting = false;
            },
            error => {
                this.isOpen = false;
                this.isOpenText = "FEHLER: " + error;
                this.isRequesting = false;
            }
        )
    }
    
    clickClose(): void {
        this.isRequesting = true;
        this.cashPointService.close().subscribe(
            data => {
                this.isOpen = false;
                this.isOpenText = "geschlossen.";
                this.isRequesting = false;
            },
            error => {
                this.isOpen = false;
                this.isOpenText = "FEHLER: " + error;
                this.isRequesting = false;
            }
        )
    }
}
