import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import {Observable } from 'rxjs/Observable';

import { Employee } from './employee.model';
import 'rxjs/add/operator/map';

@Injectable()
export class EmployeeSearchService{
    //private employeesUrl = 'http://localhost:3000/employees/name=';
    private headers = new Headers({'Content-Type': 'application/json'});

    constructor(private http: Http) {}

    search(term: string): Observable<Employee[]>{
        return this.http
                    .get(`/employees/name=${term}`)
                    .map(response => response.json() as Employee[]);
    }
}