import { NgModule }      from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


import { AdminComponent } from './admin/admin.component';
import { EmployeeComponent } from './employees/employee/employee.component';
import { EmployeeListComponent} from './employees/employee-list/employee-list.component';
import { PaymentComponent } from './payments/payment/payment.component';
import { PaymentListSimpleComponent } from './payments/payment-list-simple/payment-list-simple.component';
import { PaymentListComponent} from './payments/payment-list/payment-list.component';
import { JobComponent } from './jobs/job/job.component';
import { JobListComponent} from './jobs/job-list/job-list.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './guards/auth.guard';
import { RootAuthGuard } from './guards/root.auth.guard';

const routes: Routes = [
  {
    path: 'home',
    component: RegisterComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'settings',
    component: AdminComponent,
    canActivate: [RootAuthGuard]
  },
  {
    path: 'employees',
    component: EmployeeListComponent,
    canActivate: [RootAuthGuard] 
  },
  {
    path: 'employees/:id',
    component: EmployeeComponent,
    canActivate: [RootAuthGuard] 
  },
  {
    path: 'paymentsSimple',
    component: PaymentListSimpleComponent,
    canActivate: [AuthGuard] 
  },
  {
    path: 'payments',
    component: PaymentListComponent,
    canActivate: [RootAuthGuard]
  },
  {
    path: 'payments/:id',
    component: PaymentComponent,
    canActivate: [RootAuthGuard] 
  },
  {
    path: 'jobs',
    component: JobListComponent,
    canActivate: [RootAuthGuard] 
  },
  {
    path: 'jobs/:id',
    component: JobComponent,
    canActivate: [RootAuthGuard] 
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
    imports: [ RouterModule.forRoot(routes)],
    exports: [ RouterModule ]
})
export class AppRoutingModule{}